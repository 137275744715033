@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;

.host-meetng__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07;
  //padding-left: $spacing-06;
}

.host-meetng__heading {
  @include type-style('productive-heading-05');
}

.host-meetng__banner,
.host-meetng__r2,
.host-meetng__r3 {
  margin-left: 20px;
  margin-right: 20px;

  @include breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }
}
