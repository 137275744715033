@use '@carbon/react/scss/colors';
// overriding header tooltip bg color
// because the navigation is dark themed while the content is white
// which means the dark theme tooltip bg blends into the white content bg
.cds--header__global .cds--popover-content {
  background-color: colors.$gray-20;
}

.App-logo {
  height: 4.5vmin;
  pointer-events: none;
}
