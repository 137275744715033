@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;

.video-conference__r1 {
  //background-color: $color-red-100;
  padding-top: $spacing-03;
  padding-bottom: $spacing-03;
  padding-left: $spacing-03;
  //padding-left: $spacing-06;
  //height: 12vh;
}

.video-conference__tile {
  height: 10vh;
}
